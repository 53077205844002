import intlTelInput from 'intl-tel-input'

const input = document.querySelector('#international_phone')
if (input) {
  const iti = intlTelInput(input, {
    initialCountry: 'fr',
    utilsScript: 'https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.16/js/utils.min.js'
  })

  const update_international_phone = function () {
    document.querySelector('#user_phone').value = iti.getNumber()
  }
  input.addEventListener('change', function () {
    update_international_phone()
  })
  input.addEventListener('countrychange', function () {
    update_international_phone()
  })
}
