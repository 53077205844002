import Helpers from './helpers'
import BookingService from './booking_service'

const helpers = new Helpers()

let tos
let bookingsOptions = []

window.EnableSubmit = function () {
  const form_contains_other_driver = document.querySelector('select[name="other_driver_birthday[other_driver_form(3i)]"]')
  const starts_on = document.querySelector('#booking_starts_on').value
  const ends_on = document.querySelector('#booking_ends_on').value
  if (form_contains_other_driver) {
    var day = document.querySelector('select[name="other_driver_birthday[other_driver_form(3i)]"]').value
    var month = document.querySelector('select[name="other_driver_birthday[other_driver_form(2i)]"]').value
    var year = document.querySelector('select[name="other_driver_birthday[other_driver_form(1i)]"]').value
    const d = moment(year + '-' + month + '-' + day, 'YYYY-M-D')
    var age = moment(starts_on, 'YYYY-M-D').diff(d, 'years')

    const month_licence = document.querySelector('select[name="other_driver_licence[other_driver_form(2i)]"]').value
    const year_licence = document.querySelector('select[name="other_driver_licence[other_driver_form(1i)]"]').value
    const d_licence = moment(year_licence + '-' + month_licence, 'YYYY-M')
    var age_licence = moment(ends_on, 'YYYY-M-D').diff(d_licence, 'years')
    var vars_licence = month_licence && year_licence
  }
  const vars = day && month && year

  const sbmt = document.getElementById('submit-button')

  let minimum_licence_age = document.querySelector('#minimum_licence_age_displayed').value
  let minimum_rental_age = parseInt(document.querySelector('#minimum_rental_age_displayed').value)
  if (isNaN(minimum_rental_age)) {
    minimum_rental_age = 28
  }

  if (isNaN(minimum_licence_age)) {
    minimum_licence_age = 3
  }

  let age_is_ok = false
  if (vars) {
    if (age >= minimum_rental_age && age < 100) {
      age_is_ok = true
      document.querySelector('#alert-age').classList.add('d-none')
    } else {
      document.querySelector('#alert-age').classList.remove('d-none')
    }
  }

  let licence_is_ok = false
  if (vars_licence) {
    if (age_licence >= minimum_licence_age) {
      licence_is_ok = true
      document.querySelector('#alert-licence').classList.add('d-none')
    } else {
      document.querySelector('#alert-licence').classList.remove('d-none')
    }
  }
  const div_category_alone = document.querySelector('#category_alone')
  let category_alone = false
  if (div_category_alone) {
    category_alone =
      div_category_alone.checked
  }
  const with_driver = document.querySelector('#with_driver').value === 'true'

  if ((!tos || tos.checked) && (with_driver || category_alone ||
    (licence_is_ok && age_is_ok))) {
    sbmt.classList.remove('disable')
    sbmt.disabled = false
  } else {
    sbmt.classList.add('disable')
    sbmt.disabled = true
  }
}

function uncheckValidation () {
  document.querySelectorAll('input[name="TOS"]').forEach(function (el) {
    el.checked = false
  })
  EnableSubmit()
}

function hideOtherDriverForm () {
  uncheckValidation()
  document.querySelector('#other-driver-div').classList.remove('is-visible')
  document.querySelector('#other-driver-div').querySelectorAll('input').forEach(function (item) {
    item.removeAttribute('required')
  })
  document.querySelector('#other-driver-div').querySelectorAll('select').forEach(function (item) {
    item.removeAttribute('required')
  })

  EnableSubmit()
}


let promo = { reduction: 0 }

function deliveryOptionChanged (e) {
  const selector = e.target
  deliveryOptionChangedHandler(selector)
}

function seatOptionChanged (e) {
  const selector = e.target
  seatOptionChangedHandler(selector)
}

function deliveryOptionChangedHandler (selector) {
  if (selector.checked) {
    addBookingOption(selector, 'delivery')
    bookingsOptions = bookingsOptions.filter(x => (x.type === 'delivery' && x.id === selector.value) || x.type !== 'delivery')
  }
  refreshPriceDetails()
}

function seatOptionChangedHandler (selector) {
  if (selector.checked) {
    addBookingOption(selector, 'seat')
    bookingsOptions = bookingsOptions.filter(x => (x.type === 'seat' && x.id === selector.value) || x.type !== 'seat')
  }
  refreshPriceDetails()
}

function addBookingOption (selector, type) {
  bookingsOptions.push({
    id: selector.value,
    name: selector.dataset.name,
    type
  })
}

function washOptionChangedHandler (selector) {
  if (selector.checked) {
    addBookingOption(selector, 'wash')
  } else {
    bookingsOptions = bookingsOptions.filter(x => (x.type !== 'wash'))
  }
  refreshPriceDetails()
}

function washOptionChanged (e) {
  const selector = e.target
  washOptionChangedHandler(selector)
}

function distanceChanged (e) {
  refreshPriceDetails()
}

function showOtherDriverForm () {
  uncheckValidation()
  document.querySelector('#other-driver-div').classList.add('is-visible')
  document.querySelector('#other-driver-div').querySelectorAll('input').forEach(function (item) {
    item.setAttribute('required', 'required')
  })
  document.querySelector('#other-driver-div').querySelectorAll('select').forEach(function (item) {
    item.setAttribute('required', 'required')
  })

  EnableSubmit()
}

const first_message_service = function () {
  if (!document.querySelector('#bookings_confirmation_form')) {
    return
  }

  tos = document.querySelectorAll('input[name="TOS"]')[0]
  if (tos) {
    tos.addEventListener('change', EnableSubmit)
  } else {
    EnableSubmit()
  }

  const div_selector_names = ['select[name="other_driver_birthday[other_driver_form(3i)]"]',
    'select[name="other_driver_birthday[other_driver_form(2i)]"]',
    'select[name="other_driver_birthday[other_driver_form(1i)]"]',
    'select[name="other_driver_licence[other_driver_form(2i)]"]',
    'select[name="other_driver_licence[other_driver_form(1i)]"]'
  ]
  for (const div_selector_name of div_selector_names) {
    const selector = document.querySelector(div_selector_name)
    if (selector) {
      selector.addEventListener('change', EnableSubmit)
    }
  }

  const category_alone_is_present = document.querySelector('#category_alone')
  if (category_alone_is_present) {
    document.querySelector('#category_alone').addEventListener('change', hideOtherDriverForm)
    document.querySelector('#category_many_drivers').addEventListener('click', showOtherDriverForm)
    document.querySelector('#category_gift').addEventListener('click', showOtherDriverForm)
  }

  for (const selector of document.querySelectorAll('.delivery_option')) {
    selector.addEventListener('change', deliveryOptionChanged)
  }
  for (const selector of document.querySelectorAll('.seat_option')) {
    selector.addEventListener('change', seatOptionChanged)
  }
  const selector = document.querySelector('#wash_option')
  if (selector) {
    selector.addEventListener('change', washOptionChanged)
  }
  document.querySelector('#booking_distance_max').addEventListener('change', distanceChanged)
  initializeBookingOptions()
  initializePromoForm()
  initializePriceDetails()
}

function initializePriceDetails () {
  refreshPriceDetails()
}

let debounce

function refreshPriceDetails () {
  const render_detail_price = function (response) {
    // Price text part
    const linesHTML = response.lines.filter((line) => !line.type).map((line) => helpers.line_mapper(line)).join('')
    const totalLine = helpers.total_line_mapper(response.totalPrice)
    let totalHTML = totalLine;
    const couponLine = response.lines.find((line) => line.type == 'coupon')
    if(couponLine){
      totalHTML = helpers.line_mapper(couponLine) + totalHTML
    }

    document.querySelector('.recap-prices').innerHTML = linesHTML
    document.querySelector('.total').innerHTML = totalHTML

  }

  const newCallback = function (response) {
    render_detail_price(response)
  }

  const formData = getFormData();
  const car_id = document.querySelector('#booking_car_id').value

  clearTimeout(debounce)
  // prevent multi-call during load
  debounce = setTimeout(function () {
    const booking_service = new BookingService()
    booking_service.get_detail_booking_price(formData, car_id, newCallback)
  }, 300)

}

function getFormData(){
  const formData = new FormData()
  const distanceMax = document.querySelector('#booking_distance_max').value;
  formData.append('kmInput', distanceMax)
  let delivery_option = bookingsOptions.find((el) => el.type === 'delivery')
  if (delivery_option) {
    formData.append('deliveryOption', delivery_option.id)
  }

  let wash_option = bookingsOptions.find((el) => el.type === 'wash')
  if (wash_option) {
    formData.append('washOption', wash_option.id)
  }
  if (promo.code) {
    formData.append('coupon', promo.code)
  }

  const startDate = new Date(helpers.date_safari_fix(document.querySelector('#booking_starts_on').value))
  const endDate = new Date(helpers.date_safari_fix(document.querySelector('#booking_ends_on').value))

  const starts_on = startDate.toJSON()
  const ends_on = endDate.toJSON()

  formData.append('startsOn', starts_on)
  formData.append('endsOn', ends_on)
  return formData
}

function initializePromoForm () {
  document.querySelector('#show_form').addEventListener('click', function (ev) {
    ev.target.classList.add('d-none')
    document.querySelector('#promo-form').classList.remove('d-none')
    ev.preventDefault()
  })
  const callback = function (response) {
    if (response.reduction) {
      document.querySelector('#promo-form').classList.add('d-none')
      promo.reduction = response.reduction
      promo.code = document.querySelector('#coupon').value
      refreshPriceDetails()
    } else if (response.error) {
      document.querySelector('#coupon').classList.add('is-invalid')
      if (response.error == 'NOT_FOUND') {
        document.querySelector('#couponFeedback').textContent = 'Code invalide'
      } else if (response.error == 'MIN_NOT_REACHED') {
        document.querySelector('#couponFeedback').textContent = `Ce code n’est utilisable qu’à partir de ${response.minimum_amount}€`
      } else if (response.error == 'INCOMPATIBLE_DATES') {
        const start = helpers.format_date(response.min_date)
        const end = helpers.format_date(response.max_date)
        document.querySelector('#couponFeedback').textContent = `Ce code n’est utilisable qu’entre le ${start} et le ${end}`
      }
    }
  }
  document.querySelector('#check_coupon').addEventListener('click', function (ev) {

    const booking_service = new BookingService()
    const formData = new FormData()
    const full_distance = document.querySelector('#booking_distance_max').value
    formData.append('full_distance', full_distance)
    const starts_on = document.querySelector('#booking_starts_on').value
    const ends_on = document.querySelector('#booking_ends_on').value
    const car_id = document.querySelector('#booking_car_id').value
    const coupon = document.querySelector('#coupon').value
    formData.append('startsOn', starts_on)
    formData.append('endsOn', ends_on)
    formData.append('coupon', coupon)

    booking_service.check_coupon(formData, car_id, callback)
  })
}

function initializeBookingOptions () {

  for (const selector of document.querySelectorAll('.delivery_option')) {
    deliveryOptionChangedHandler(selector)
  }

  for (const selector of document.querySelectorAll('.seat_option')) {
    seatOptionChangedHandler(selector)
  }

  const selector = document.querySelector('#wash_option')
  if (selector) {
    washOptionChangedHandler(selector)
  }

  const insurance_selector = document.querySelector('#insurance_option')
  if (insurance_selector) {
    addBookingOption(insurance_selector)
  }

  const fees_selector = document.querySelector('#fees_option')
  if (fees_selector) {
    addBookingOption(fees_selector)
  }

}

document.addEventListener('ready', function () {
  first_message_service()
})
document.addEventListener('turbo:load', function () {
  first_message_service()
})
