const submit_new_booking_service = function () {
  const bookingForm = document.getElementById('booking-form')

  if (bookingForm) {
    bookingForm.addEventListener('submit', function () {
      if (typeof window.lintrk === 'function') {
        window.lintrk('track', { conversion_id: 7202332 })
      }
    })
  }
}
document.addEventListener('ready', function () {
  submit_new_booking_service()
})
document.addEventListener('turbo:load', function () {
  submit_new_booking_service()
})
