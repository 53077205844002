const more_button_service = function () {
  const selector = document.querySelector('#js-load_more_cars_button')
  if (!selector) {
    return
  }
  selector.addEventListener('click', function (e) {
    e.preventDefault()
    const params = document.querySelector('#search_params').value
    this.querySelectorAll('div').forEach(function (el) {
      el.classList.add('loader')
    })
    fetch('/voitures.js?' + params)
      .then(function (response) {
        return response.text()
      }).then(function (responseText) {
        eval(responseText)
      })
  })
}

document.addEventListener('ready', function () {
  more_button_service()
})
document.addEventListener('turbo:load', function () {
  more_button_service()
})
