export const templatized = (template, vars = {}) => {
  const handler = new Function('vars', [
    'const tagged = ( ' + Object.keys(vars).join(', ') + ' ) =>',
    '`' + template + '`',
    'return tagged(...Object.values(vars))'
  ].join('\n'))

  return handler(vars)
}

export function sendAjaxForm(selector, callback = function () {}){
  const data = new FormData(selector);
  fetch(selector.action, {
    method: selector.method,
    body: data,
    headers: {
      'Accept': 'application/json',
      "X-Requested-With": "XMLHttpRequest"
    }
  }).then(response => {
    callback(response)
  })
}

export function debounce(func, timeout = 300) {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => { func.apply(this, args); }, timeout);
  };
}
