const customCheckValidity = function () {
  const regex = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).{8,32}$/
  return regex.test(document.querySelector('#user_password').value)
}
'use strict'
window.addEventListener('load', function () {
  // Fetch all the forms we want to apply custom Bootstrap validation styles to
  const forms = document.getElementsByClassName('needs-validation')
  // Loop over them and prevent submission
  const validation = Array.prototype.filter.call(forms, function (form) {
    form.addEventListener('submit', function (event) {
      const classList = document.querySelector('#pwd-feedback').classList
      if (customCheckValidity() === false) {
        classList.add('invalid-feedback')
        classList.remove('text-muted')
        event.preventDefault()
        event.stopPropagation()
      } else {
        classList.removeClass('invalid-feedback')
        classList.remove('text-muted')
      }
      form.classList.add('was-validated')
    }, false)
  })
}, false)
