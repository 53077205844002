function invoicesInitialize() {
    const yearSelect = document.querySelector("#js-invoices-year")
    if(!yearSelect){
        return
    }

    const yearForm = document.querySelector('#js-invoices-year-form')
    const downloadSelectArray = document.querySelectorAll(".js-invoices-location")

    yearSelect.addEventListener("change", () => yearForm.submit())
    downloadSelectArray.forEach((selectEl) => {
        selectEl.addEventListener("change", () => {
            const docLink = selectEl.value
            if (docLink) {
                window.location.assign(docLink)
            }
        })
    })
}

document.addEventListener('ready', function () {
    invoicesInitialize()
})

document.addEventListener('turbo:load', function () {
    invoicesInitialize()
})