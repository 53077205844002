export default class BookingService {
  get_booking_price (formData, car_id, callback) {
    const formDataAsString = this.#formDataAsString(formData)
    const request = this.#request()

    const url = `/cars/${car_id}/bookings/price?` + formDataAsString
    this.#send_request(request, url, formData, callback)
  }
  get_detail_booking_price (formData, car_id, callback) {
    const formDataAsString = this.#formDataAsString(formData)
    const request = this.#request()

    const url = `/cars/${car_id}/bookings/details_price?` + formDataAsString
    this.#send_request(request, url, formData, callback)
  }
  check_coupon(formData, car_id, callback){
    const formDataAsString = this.#formDataAsString(formData)
    const request = this.#request()
    const url = `/cars/${car_id}/bookings/check_coupon?` + formDataAsString
    this.#send_request(request, url, formData, callback)
  }
  #request(){
    return new XMLHttpRequest()
  }
  #formDataAsString(formData){
    return new URLSearchParams(formData).toString()
  }
  #send_request(request, url, formData, callback){
    request.onreadystatechange = function () {
      if (request.readyState === 4) {
        const response = JSON.parse(request.response)
        callback(response)
      }
    }

    request.open('GET', url)
    request.setRequestHeader('X-Requested-With', 'XMLHttpRequest')
    request.send(formData)
  }

  get_tenant_booking_price (formData, booking_id, callback) {
    const formDataAsString = this.#formDataAsString(formData)
    const request = this.#request()
    const url = `/bookings/${booking_id}/tenant_visible_price?` + formDataAsString
    this.#send_request(request, url, formData, callback)
  }
}
