const car_price_periods_service = function () {
  if (!document.querySelector('#car_price_periods')) {
  }

  document.querySelectorAll('.date_field').forEach(el => {
    const index = el.getAttribute('data-index')
    // Flatpickr setup
    flatpickr(el, {
      altInput: false,
      dateFormat: 'Y-m-d',
      position: 'below',
      locale: 'fr',
      disableMobile: true,
      plugins: [new rangePlugin({ input: `#car_car_price_periods_attributes_${index}_ends_on` })]
    })
  }
  )
}

document.addEventListener('ready', function () {
  car_price_periods_service()
})

document.addEventListener('turbo:load', function () {
  car_price_periods_service()
})

document.addEventListener('roadstr:car_price_period_row_added', function () {
  document.querySelector('#car-price-periods-form-submit').classList.remove('d-none')
  car_price_periods_service()
})
