export default class SearchFormAction {
  constructor () {
    this.header = document.querySelectorAll('#js-cars-search-header')
  }

  submitFormHeader () {
    document.querySelector('.header-search-input input').classList.add('is-focused')
    const location = document.querySelector('#js-search_location_display_autocomplete').value
    const message = (location) ? 'Recherche des voitures en cours à ' + location + '...' : 'Recherche des voitures en cours...'
    this.launchLoader(message)

    document.querySelector('#home-search').submit()
  }

  launchLoader (message) {

    if (this.header.length > 0) {
      this.header.innerHTML = null
    }
    document.querySelector('.car-items-loader').classList.add('is-active')
    document.querySelector('#loader_search').textContent = message
  }

  closeLoader(){
    document.querySelector('.car-items-loader').classList.remove('is-active')
  }
}
