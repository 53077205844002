import BookingService from './booking_service'
import Helpers from './helpers'

const helpers = new Helpers();

const extension_changed = function () {
  const starts_on_date = `${document.querySelector('#ask_extension_form #starts_on').value}`
  const starts_on = new Date(starts_on_date).toJSON()
  const ends_on_date = `${document.querySelector('#ask_extension_form #ends_on').value} ${document.querySelector('#ask_extension_form #ends_at').value}`
  const ends_on = new Date(ends_on_date).toJSON()
  const km_input = document.querySelector('#ask_extension_form #distance').value
  const formData = new FormData()
  formData.append('full_distance', km_input)

  if (!starts_on || !ends_on) {
    return
  }
  formData.append('startsOn', starts_on)
  formData.append('endsOn', ends_on)
  const callback = function (response) {
    document.querySelector('#ask_extension_form_price').innerHTML = helpers.extension_price(response.newPrice)
  }

  const car_id = document.querySelector('#car_id').value

  const booking_service = new BookingService()
  booking_service.get_booking_price(formData, car_id, callback)
}
const extension_modal_service = function () {
  const extensionForm = document.querySelector('#ask_extension_form')
  extensionForm.addEventListener('change', function () {
    extension_changed()
  })
}
document.addEventListener('ready', function () {
  const page_contains_extension_modal = document.querySelector('#extensionModal')
  if (page_contains_extension_modal) {
    extension_modal_service()
  }
})

document.addEventListener('turbo:load', function () {
  const page_contains_extension_modal = document.querySelector('#extensionModal')
  if (page_contains_extension_modal) {
    extension_modal_service()
  }
})
