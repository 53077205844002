const new_car_service = function (event) {
  function change_low_power_disabled_to (value) {
    const car_power = document.querySelector('#car_power')

    const car_power_options = document.querySelectorAll('#car_power option');
    [...car_power_options].forEach(function (option) {
      if (option.value < 60) {
        option.disabled = value
      }
    })
    if (value == true && car_power.value < 60) {
      car_power.value = 60
    }
  }

  function disable_power () {
    change_low_power_disabled_to(true)
  }

  function enable_power () {
    change_low_power_disabled_to(false)
  }

  function process (value) {
    if (value > 1990) {
      disable_power()
    } else {
      enable_power()
    }
  }

  if (document.getElementById('new_car')) {
    const car_year_div = document.querySelector('#car_year')
    car_year_div.addEventListener('change', (e) => {
      process(parseInt(e.target.value))
    })
    process(parseInt(car_year_div.value))
  }
}

document.addEventListener('ready', new_car_service, false)
document.addEventListener('turbo:load', new_car_service, false)
