import Helpers from './helpers'
const conversation_service = function(){
    const helpers = new Helpers();
    const ATTACHMENT_MAX_SIZE = 12; //Booking::ATTACHMENT_MAX_SIZE

    const objDiv = document.getElementById("js-booking-dashboard-conversation");
    if(!objDiv){
        return;
    }
    setTimeout(function () {
        objDiv.scrollTop = objDiv.scrollHeight;
        if (helpers.is_mobile()) {
            window.scrollTo(0, document.body.scrollHeight);
        }
    }, 500)
    document.querySelector('textarea').addEventListener('click', function (e) {
        e.target.classList.add('is-typing')
    })

    document.querySelector('textarea').addEventListener('input', function (e) {
        const target = e.target;
        const classList = target.classList;
        if (target.value === '') {
            classList.remove('is-typing')
            classList.remove('has-content')
        } else {
            classList.add('has-content')
        }
    })

    document.querySelector("#fileUpload")?.addEventListener('click', function (event) {
        event.preventDefault();
        document.querySelector("#mailboxer_conversation_attachment").click();
    })

    document.querySelector('.upload')?.addEventListener("change", function (e) {
        const target = e.target;
        if (target.files && target.files[0]) {

            let mb_size = target.files[0].size / 1024 / 1024;
            if (mb_size > ATTACHMENT_MAX_SIZE) {
                document.querySelector('#error-msg').classList.remove('d-none');
                document.querySelector('#validate-conversation').disabled = true;
            } else {
                document.querySelector('#error-msg').classList.add('d-none');
                document.querySelector('#validate-conversation').disabled = false;
            }

            var reader = new FileReader();

            reader.onload = function (e) {
                document.querySelector('#imgPreview')
                    .setAttribute('src', e.target.result)
            };

            reader.readAsDataURL(target.files[0]);
        }
    })

    if (helpers.is_mobile()) {
        booking_dashboard_mobile_toggle();
    }
}
function booking_dashboard_mobile_toggle() {
    const conversation = document.querySelector("#js-booking-dashboard-conversation-container");
    const bookingInfos = document.querySelector("#js-booking-dashboard-infos-container");
    const conversationToggle = document.querySelector("#js-mobile-show-booking-infos");
    const bookingInfosToggle = document.querySelector("#js-mobile-show-conversation");

    const action = function() {toggle([conversation, bookingInfos])};
    const containers = [conversationToggle, bookingInfosToggle];

    for (let container of containers) {
        container.addEventListener("click", action);
    }
}
function toggle(elements) {
    for (let element of elements) {
        if (element.classList.contains("d-none")) {
            element.classList.remove("d-none");
        } else {
            element.classList.add("d-none");
        }
    }
}
document.addEventListener("turbo:load", function () {
    conversation_service();
})
document.addEventListener("ready", function () {
    conversation_service();
})